import { render, staticRenderFns } from "./GlobalForm.vue?vue&type=template&id=1dbb392e&scoped=true&"
import script from "./GlobalForm.vue?vue&type=script&lang=js&"
export * from "./GlobalForm.vue?vue&type=script&lang=js&"
import style0 from "./GlobalForm.vue?vue&type=style&index=0&id=1dbb392e&scoped=true&lang=scss&ref=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dbb392e",
  null
  
)

export default component.exports