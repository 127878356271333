import Layout from '@/layout/layout'

const ChangePhoneRouter = {
    path: '/changePhone',
    component: Layout,
    redirect: 'noRedirect',
    name: '五折焕新活动',
    children: [
        {
            path:'modelConfig',
            component: () => import('@/views/changePhone/modelConfig/index'),
            name: '机型配置',
            meta: { title: '机型配置', noCache: true }
        },  {
            path:'orderList',
            component: () => import('@/views/changePhone/orderList/index'),
            name: '五折焕新订单列表',
            meta: { title: '订单列表', noCache: true }
        },  {
            path:'storeList',
            component: () => import('@/views/changePhone/storeList/index'),
            name: '门店列表',
            meta: { title: '门店列表', noCache: true }
        },  {
            path:'operationChannelConfig',
            component: () => import('@/views/changePhone/operationChannelConfig/index.vue'),
            name: '运营通道配置',
            meta: { title: '运营通道配置', noCache: true }
        },{
            path:'modelOperation',
            component: () => import('@/views/changePhone/modelConfig/operate.vue'),
            name: '机型操作',
            meta: { title: '机型操作', noCache: true }
        }, {
            path:'storeOperation',
            component: () => import('@/views/changePhone/storeList/operate.vue'),
            name: '门店操作',
            meta: { title: '门店操作', noCache: true }
        }, {
            path:'channelOperation',
            component: () => import('@/views/changePhone/operationChannelConfig/operate.vue'),
            name: '运营通道操作',
            meta: { title: '运营通道操作', noCache: true }
        },{
            path:'newPhoneBuyPrice',
            component: () => import('@/views/changePhone/operationChannelConfig/newPhoneBuyPrice.vue'),
            name: '机型设置',
            meta: { title: '机型设置', noCache: true }
        },{
            path:'agentManage',
            component: () => import('@/views/changePhone/agentManage/index.vue'),
            name: '代理商管理',
            meta: { title: '代理商管理', noCache: true }
        },{
            path:'partnerManage',
            component: () => import('@/views/changePhone/partnerManage/index.vue'),
            name: '合伙人管理',
            meta: { title: '合伙人管理', noCache: true }
        },{
            path:'partnerOperate',
            component: () => import('@/views/changePhone/partnerManage/operate.vue'),
            name: '合伙人操作',
            meta: { title: '合伙人操作', noCache: true }
        },{
            path:'agentOperate',
            component: () => import('@/views/changePhone/agentManage/operate.vue'),
            name: '代理商操作',
            meta: { title: '代理商操作', noCache: true }
        },

    ]
}

export default ChangePhoneRouter
